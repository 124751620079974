import React from 'react'
import { contactsConstants } from '../../../../constants'

import css from './Social.module.scss'

const Social = () => (
  <ul className={css.UlSocial}>
    {contactsConstants.link.map((s) => (
      <li key={s.href}>
        <a
          href={s.href}
          rel='noopener noreferrer'
          target={s.target}
          className={css.LnkSocial}
        >
          <i className={s.icon}></i>
        </a>
      </li>
    ))}
  </ul>
)

export default Social
