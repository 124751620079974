import React from 'react'
import { NavLink } from 'react-router-dom'

import { menuConstants } from '../../../../../constants'
import css from './Menu.module.scss'

const Menu = ({ clickMenuHandler }) => (
  <ul className={css.Ul}>
    {menuConstants.links.map((l) => (
      <li key={l.id}>
        <NavLink
          onClick={clickMenuHandler}
          to={l.to}
          className={({ isActive }) => (isActive ? css.NavActive : css.NavLink)}
        >
          {l.label}
        </NavLink>
      </li>
    ))}
  </ul>
)

export default Menu
