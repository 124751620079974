import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyCm3kZr0t58B_UzY8_dZBZX8fIOk8KPumg',
  authDomain: 'killhouse-fe50f.firebaseapp.com',
  databaseURL: 'https://killhouse-fe50f.firebaseio.com',
  projectId: 'killhouse-fe50f',
  storageBucket: 'killhouse-fe50f.appspot.com',
  messagingSenderId: '984658457973',
  appId: '1:984658457973:web:4e96950c3e81ca99eecf3c',
  measurementId: 'G-ZLWFPY1Y0F'
}

export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const db = getFirestore(app)
export const storage = getStorage(app)
