import { eventsConstants } from '../constants'

export const loadEvents = () => {
  return {
    type: eventsConstants.EVENTS_INIT
  }
}

export const updateEvents = (payload) => {
  return {
    type: eventsConstants.EVENTS_UPDATE,
    payload
  }
}

export const removeEvents = (payload) => {
  return {
    type: eventsConstants.EVENTS_REMOVE,
    payload
  }
}
