import { combineReducers } from 'redux'

import gallery from './gallery.reducer'
import events from './events.reducer'

const rootReducer = combineReducers({
  gallery,
  events
})

export default rootReducer
