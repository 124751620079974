import { Outlet } from 'react-router-dom'

import css from './ContentLayout.module.scss'

const ContentLayout = () => (
  <div className={css.Content}>
    <Outlet />
  </div>
)

export default ContentLayout
