import { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'

import { loadEvents, loadGallery } from '../../../../redux/actions'

const LoadCollections = (props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadGallery())
    dispatch(loadEvents())
  }, [dispatch])

  return null
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {
  loadGallery,
  loadEvents
})(LoadCollections)
