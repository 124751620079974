import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import css from './NavBar.module.scss'
import Menu from './Menu/Menu'
import Share from './Share/Share'

const NavBar = () => {
  const { pathname } = useLocation()
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const clickMenuHandler = () => {
    if (menuIsOpen) {
      setMenuIsOpen(false)
    }
  }

  const [bodyOffset, setBodyOffset] = useState(
    document.body.getBoundingClientRect()
  )

  const listener = () => {
    setBodyOffset(document.body.getBoundingClientRect())
  }

  useEffect(() => {
    document.addEventListener('scroll', listener)
    return () => {
      document.removeEventListener('scroll', listener)
    }
  })

  return (
    <React.Fragment>
      <div
        className={`${css.HamburgerContainer} ${
          pathname === '/' ? '' : css.Full
        } ${
          Math.abs(bodyOffset.top) > 0 && pathname !== '/' ? css.Shadow : ''
        }`}
      >
        <button
          className={`hamburger hamburger--squeeze ${
            menuIsOpen ? 'is-active' : ''
          }`}
          type='button'
          aria-label='Menu'
          onClick={() => setMenuIsOpen(!menuIsOpen)}
        >
          <span className='hamburger-box'>
            <span className='hamburger-inner'></span>
          </span>
        </button>
      </div>
      <nav className={`${css.Nav} ${menuIsOpen ? css.NavOpen : ''}`}>
        <Menu clickMenuHandler={clickMenuHandler} />
        <Share />
      </nav>
    </React.Fragment>
  )
}

export default NavBar
