import React from 'react'

import Logo from '../../../assets/logo_white.svg'
import css from './PageLoader.module.scss'

const PageLoader = () => (
  <div className={css.Spinner}>
    <div className={css.Circle}>
      <img src={Logo} alt='Kill House' />
    </div>
  </div>
)

export default PageLoader
