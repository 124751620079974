import { takeEvery } from 'redux-saga/effects'
import { galleryConstants, eventsConstants } from '../constants'
import { loadGallerySaga } from './gallery.sagas'
import { loadEventsSaga } from './events.sagas'

export function* watchGallery() {
  yield takeEvery(galleryConstants.GALLERY_INIT, loadGallerySaga)
}

export function* watchEvents() {
  yield takeEvery(eventsConstants.EVENTS_INIT, loadEventsSaga)
}
