export const menuConstants = {
  links: [
    { id: 'home', label: 'Home', to: '/' },
    {
      id: 'structure',
      label: 'Struttura',
      to: '/structure'
    },
    {
      id: 'events',
      label: 'Eventi',
      to: '/events'
    },
    {
      id: 'gallery',
      label: 'Gallery',
      to: '/gallery'
    },
    {
      id: 'videos',
      label: 'Videos',
      to: '/videos'
    }
  ]
}
