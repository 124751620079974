export const contactsConstants = {
  link: [
    {
      href: 'https://www.facebook.com/killhouse.it/',
      target: '_blank',
      icon: 'fab fa-facebook-f'
    },
    {
      href: 'https://www.instagram.com/killhouse.it/',
      target: '_blank',
      icon: 'fab fa-instagram'
    },
    {
      href: 'https://twitter.com/killhouseit',
      target: '_blank',
      icon: 'fab fa-twitter'
    },
    {
      href: 'mailto:info@killhouse.it',
      icon: 'far fa-envelope'
    }
  ]
}
