import { galleryConstants } from '../constants'

export const loadGallery = () => {
  return {
    type: galleryConstants.GALLERY_INIT
  }
}

export const updateGallery = (payload) => {
  return {
    type: galleryConstants.GALLERY_UPDATE,
    payload
  }
}

export const removeGallery = (payload) => {
  return {
    type: galleryConstants.GALLERY_REMOVE,
    payload
  }
}
