import React from 'react'

import css from './Share.module.scss'
import {
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  LinkedinIcon
} from 'react-share'

const shareUrl = 'http://killhouse.it'
const shareTitle =
  'Kill House - Poligono al chiuso per formazione con armi T4E Umarex'

const Share = () => (
  <React.Fragment>
    <span className={css.ShareTitle}>Share</span>
    <ul className={css.UlShare}>
      <li>
        <FacebookShareButton url={shareUrl}>
          <FacebookIcon size={30} />
        </FacebookShareButton>
      </li>
      <li>
        <TwitterShareButton url={shareUrl} title={shareTitle}>
          <TwitterIcon size={30} />
        </TwitterShareButton>
      </li>
      <li>
        <LinkedinShareButton url={shareUrl} title={shareTitle}>
          <LinkedinIcon size={30} />
        </LinkedinShareButton>
      </li>
      <li>
        <WhatsappShareButton url={shareUrl} title={shareTitle}>
          <WhatsappIcon size={30} />
        </WhatsappShareButton>
      </li>
      <li>
        <EmailShareButton subject={shareTitle} url={shareUrl}>
          <EmailIcon size={30} />
        </EmailShareButton>
      </li>
    </ul>
  </React.Fragment>
)

export default Share
